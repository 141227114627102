var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.news
    ? _c(
        "div",
        { staticClass: "news-wrapper" },
        _vm._l(_vm.news, function (article) {
          return _c("div", { key: article.id, staticClass: "article" }, [
            _c("a", { staticClass: "headline", attrs: { href: article.url } }, [
              _vm._v(_vm._s(article.title)),
            ]),
            _c("div", { staticClass: "article-meta" }, [
              _c("span", { staticClass: "publisher" }, [
                _vm._v(_vm._s(article.author)),
              ]),
              _c("span", { staticClass: "date" }, [
                _vm._v(_vm._s(_vm._f("date")(article.published))),
              ]),
            ]),
            _c("p", { staticClass: "description" }, [
              _vm._v(_vm._s(article.description)),
            ]),
            article.image && !_vm.hideImages
              ? _c("img", {
                  staticClass: "thumbnail",
                  attrs: { src: article.image, alt: "Image" },
                })
              : _vm._e(),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }